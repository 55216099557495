import "../tagi/tagi.css"
import {useEffect, useState} from "react";

const Tagi = ({ onClose, sklep, selectedFlavor, setSelectedFlavor, showFlavors ,setShowFlavors }) => {

    const [data, setData] = useState([]);
    const [done, setDone] = useState(false);
    const server_url = "https://vc-catalog-api.omnihub.tech/"//"http://127.0.0.1:8000/"
    const [selectedFlavors, setSelectedFlavors] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            await fetch(`${server_url}product_list/tags/${sklep}` + (sklep !== "" ? "/" : ""), {
                method: 'GET',
                headers: {
                    'Authorization': 'Api-Key jNewbFc5.xbDd0Iu22AZbUz2Lp9OWjlyJFOOatSd0'
                }
            })
                .then((response) => response.json())
                .then((json) => {
                    setData(json);
                    setDone(true);
                });
        }
        fetchData();
    }, []);




    const handleAddFlavor =(flavor) => {
        const correctFlavorName = flavor.trim().charAt(0).toUpperCase() + flavor.trim().slice(1).toLowerCase();
        
        if(selectedFlavor.toLowerCase().includes(correctFlavorName.toLowerCase())){
            handleDeselectFlavor(correctFlavorName);
        } else {
            setSelectedFlavor((selectedFlavor ? selectedFlavor + "," : "") + correctFlavorName);
            setShowFlavors(true)
            setSelectedFlavors(prevFlavors => [...prevFlavors, correctFlavorName]);
        }
    }
    
    const handleDeselectFlavor = (flavorToRemove) => {
        setSelectedFlavor(selectedFlavor.split(',').map(flavor => flavor.trim()).filter(flavor => flavor.toLowerCase() !== flavorToRemove.toLowerCase()).join(','));
        setSelectedFlavors(prevFlavors => prevFlavors.filter(flavor => flavor !== flavorToRemove));
        
        selectedFlavor.split(',').forEach(element => {
            const className = element.trim().replace(" ","");
            const elementy = document.getElementsByClassName(className);
            for (let i = 0; i < elementy.length; i++) {
                if (selectedFlavors.includes(element.trim())) {
                    if (!elementy[i].classList.contains('selected-flavor')) {
                        elementy[i].classList.add('selected-flavor');
                    }
                } else {
                    if (elementy[i].classList.contains('selected-flavor')) {
                        elementy[i].classList.remove('selected-flavor');
                    }
                }
            }
        });
        
        
    }
    
    useEffect(() => {
        
    }, [selectedFlavor, selectedFlavors]);
    
    


    if (!done) { //to musi być bo jest error jak nie wczyta danych a spróbuje pokazać
        return (
            <>
                <div className="overlay" onClick={onClose}></div>
                <div className="center">
                    <div className="container">
                        <div className="kolumna">
                            <h2>Smaki</h2>
                            <div className="overflow">

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }


    return(
        <>
            <div className="overlay" onClick={onClose}></div>
            <div className="center">
                <div className="container">
                    <div className="kolumna">
                        <div style={{display:"flex"}}>
                            <h2 style={{width:"95%"}}>Smaki</h2>
                            <i className="bi-x-lg" style={{fontSize: '2em',marginTop:"3px"}} onClick={onClose}></i>
                        </div>

                        <div className="overflow">
                            {data.flavors.map(
                                (flavor, index) => (
                                    <>
                                        <h2 style={{margin: "0"}}>{flavor[0][0].toUpperCase()}</h2>
                                        <div className="kamilrzecz">
                                            {flavor.filter(nazwa => nazwa !== selectedFlavor).map(
                                                (nazwa, index2) => (
                                                    <>
                                                        <div
                                                            className={(nazwa.charAt(0).toUpperCase() + nazwa.slice(1).toLowerCase()).replace(" ", "") + (selectedFlavors.includes(nazwa.charAt(0).toUpperCase() + nazwa.slice(1).toLowerCase()) ? ' selected-flavor' : '')}
                                                            onClick={() => handleAddFlavor(nazwa)}>
                                                            <p>{nazwa.charAt(0).toUpperCase() + nazwa.slice(1).toLowerCase()}</p>
                                                            {index === data.flavors.length - 1 && index2 === flavor.length - 1 && (
                                                                <div style={{display: "none"}}>
                                                                    {
                                                                        setTimeout(() => {
                                                                            selectedFlavor.split(',').forEach(element => {
                                                                                const elementy = document.getElementsByClassName(element.replace(" ", ""));
                                                                                for (let i = 0; i < elementy.length; i++) {
                                                                                    elementy[i].classList.add('selected-flavor');
                                                                                }
                                                                            })
                                                                        }, "69")
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                )
                                            )}
                                        </div>
                                    </>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Tagi
