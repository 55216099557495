import { useState } from "react"

export default function SidebarItem({item, setCategory, category, mark, setMark, setAnyCategoryOpen, setOpenItem, openItem, activeItem, setActiveItem, activeTitle, setActiveTitle}){
    const handleCategoryChange= () => {
        if (item.title === openItem) {
            setOpenItem(null);
                setAnyCategoryOpen(true);
                setCategory("");
                setMark("");
                setActiveItem(null); // Resetujemy stan activeItem
                // window.scrollTo(-1000,-1000);
                setActiveTitle(item.title)
                // console.log(item.title)
        } else {
            setCategory(item.title);
            setOpenItem(item.title);
            setAnyCategoryOpen(false);
            setActiveItem(item.title); // Ustawiamy stan activeItem na tytuł klikniętego elementu
            // window.scrollTo(-1000,-1000);
        }
    }
    
    const handleMarkChange = (e) => {
        e.stopPropagation();
        setMark(item.title);
        setActiveItem(item.title); // Ustawiamy stan activeItem na tytuł klikniętego elementu
    }
    
    const isOpen = item.title === openItem;
    const isActive = item.title === activeItem; // Sprawdzamy, czy element jest aktywny
    const isActiveTitle = item.title === activeTitle

    if(item.childrens){
        return(
            <>
                <div id="titleOpen" className={isOpen ? "sidebar-item open" : "sidebar-item"} onClick={handleCategoryChange}>
                    <div className="sidebar-title">
                        <span>
                            {item.title} 
                        </span>
                        <i className="bi-chevron-down toggle-btn"></i>
                    </div>
                    <div className="sidebar-content">
                        {item.childrens.map((child, index) => <SidebarItem key={index} item={child} setCategory={setCategory} category={category} mark={mark} setMark={setMark} setOpenItem={setOpenItem} openItem={openItem} activeItem={activeItem} setActiveItem={setActiveItem}/>)}
                    </div>
                </div>
            </>
        )
    }else{
        return(
            <>
                <a id="markOpen" className={isActive ? "sidebar-item plain active" : "sidebar-item plain"} onClick={handleMarkChange}>
                    <span>
                        {item.icon && <i className={item.icon}></i>}
                        {item.title}
                    </span>

                </a>
            </>
        )
    }
}
