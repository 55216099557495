import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion'; // importuj framer-motion
import "../ruletka/ruletka.css"
import skrzynek from "../ruletka/skrzynek.png"

const Ruletka = ({ handleOpenRuletka, items, setIsRuletkaRunning }) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(items[0].category);
    const [key, setKey] = useState(0); // dodaj klucz stanu
    const [itemydoruletki, setItemydoruletki] = useState([])
    const [openSkrzynek, setOpenSkrzynek] = useState(true)

    const handleClick = () => {
        
        setOpenSkrzynek(false)

        if (items) {
            
            setIsRuletkaRunning(true)
            document.getElementById("kamilid").style.borderTop = "2px solid black"
            document.getElementById("kamilid").style.borderBottom = "2px solid black"
            document.getElementById("kamilid").style.display = "flex"
            document.getElementById("wygrana").style.display = "none"

            document.getElementById("guzik-ruletka").disabled = true;
            document.getElementById("guzik-ruletka").style.opacity = "0.6"

            const bestsellerItems = items.filter(item => item.bestseller && item.category === selectedCategory);
            const randomIndex = Math.floor(Math.random() * bestsellerItems.length);

            const categoryItems = items.filter(item => item.category === selectedCategory &&
                !(item.image.toString().split("/").splice(-1)[0] === "Logo_Vape&Chill_White.png"));
            const tempItemydoruletki = []
            for (let i = 0; i < 20; i++) {
                const item = categoryItems[Math.floor(Math.random() * (categoryItems.length - 0))]
                tempItemydoruletki.push(item)
                const img = new Image();
                img.src = item.image;
            }
 
            setItemydoruletki(tempItemydoruletki);

            setSelectedItem(bestsellerItems[randomIndex]);

            setKey(prevKey => prevKey + 1); // zaktualizuj klucz

          
            setTimeout(() => {
                document.getElementById("kamilid").style.display = "none"
                document.getElementById("wygrana").style.display = "flex"
                document.getElementById("guzik-ruletka").disabled = false;
                document.getElementById("guzik-ruletka").style.opacity = "1"
                setIsRuletkaRunning(false)
            }, "2500");


        } else {
            console.log('Items is undefined');
        }
    }

    useEffect(() => {
        console.log("skrzynka po ", openSkrzynek);
    }, [openSkrzynek]);

    const handleChange = (event) => {
        setSelectedCategory(event.target.value);
    }

    // Tworzenie listy unikalnych kategorii
    const categories = [...new Set(items.filter(item => item.bestseller).map(item => item.category))];

    return (
        <>
            <div className="overlay" onClick={handleOpenRuletka}></div>
            <div className="ruletka-container-popup">
            {openSkrzynek && (
                <div className="skrzynka-popup">
                     <div className="skrzynka">
                        <img src={skrzynek} alt="skrzynek" style={{width: 350}} />
                    </div>
                </div>
            )}
                <div className="ruletka-image">
                    <div className='kamil' id="kamilid">
                    <div className="kurwa"></div>
                        {itemydoruletki.map(
                            (item, index) => (
                                <>

                                    {index === 2 ? (
                                        <>
                                            <motion.div 
                                                key={key} // dodaj klucz do komponentu motion.img
                                                //src={item.image} 
                                                initial={{ opacity: 1, x: -2000 + ((index + 1) * 10) }}
                                                animate={{ opacity: 1, x: 1500 + ((index+1) * 10) }}
                                                transition={{ duration:2 }}
                                                // style={{borderColor:"green"}}
                                                className='blok'
                                            >
                                                <img src={selectedItem.image} alt={index} loading={"eager"}></img>

                                            </motion.div>
                                        </>
                                    ) : (
                                        <>
                                            <motion.div 
                                                key={key} // dodaj klucz do komponentu motion.img
                                                //src={item.image} 
                                                initial={{ opacity: 1, x: -2000 + ((index + 1) * 10) }}
                                                animate={{ opacity: 1, x: 1500 + ((index+1) * 10) }}
                                                transition={{ duration:2 }}
                                                style={{}}
                                                className='blok'
                                            >
                                                <img src={item.image} alt={index} loading={"eager"}></img>

                                            </motion.div>
                                        </>
                                    )}
                                  
                                </>
                            )
                        )}
                    </div>
                    <div id='wygrana' className='wygrana'>
                        {selectedItem && (
                            <>
                                <div className='obrazek'><img src={selectedItem.image}></img></div>
                                <div className='prawotekst'>
                                    {selectedItem && 
                                        <>
                                            <h1>{selectedItem.name}</h1>
                                            <h2>{selectedItem.mark}</h2>
                                            <div>
                                                <h3>Smaki</h3>
                                                <ul>
                                                    {selectedItem.flavor.map((flavor, index) => (
                                                        <li key={index}>{flavor.charAt(0).toUpperCase() + flavor.slice(1).toLowerCase()}</li>
                                                    ))}
                                                </ul>
                                               
                                            </div>
                                        </>
                                    }
                                </div>
                            </>
                        )}
                    </div>
                    
                </div>
                <div className="ruletka-text-content">
                    <i className="bi-x-lg" style={{fontSize: '2em', position:"absolute",bottom:"93%",right:"1%"}}
                       onClick={handleOpenRuletka}></i>
                    <div className='padding'>
                        <h2 style={{width:"95%"}}>Nie masz pomysłu na płyn?</h2>
                        <p>Dzięki niesamowitej losowarce smaków wylosuj se smak i sie ciesz, dalej tam jakiś tekst coś
                            tam.</p>
                        <div className='roll'>
                            <select value={selectedCategory} onChange={handleChange}>
                                {categories.map((category, index) => (
                                    <option key={index} value={category}>{category}</option>
                                ))}
                            </select>
                            <div className='przycisk-container'>
                                <button id="guzik-ruletka" onClick={handleClick} className='przycisk'
                                        style={{marginTop: "40px", width: "90%"}}>
                                    zakręć
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Ruletka;
