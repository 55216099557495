import React, { useEffect, useState, useRef } from 'react';
import Sidebar from './sidebar/sidebar';
import "../home/home.css"
import Tagi from './tagi/tagi';
import Glowny from './searchbar';
import { Objects } from './home-objects';
// import { FontAwesomeIcon } from 'react-icons/fa';
import { FaDice } from 'react-icons/fa';
import Ruletka from './ruletka/ruletka';
import ImageWebp from 'react'



const Home = ({ onInactive, sklep }) => {
    
    const [category, setCategory] = useState("")
    const [mark, setMark] = useState("")
    const [anyCategoryOpen, setAnyCategoryOpen] = useState(false);
    const [openItem, setOpenItem] = useState("")
    const itemContainerRef = useRef();
    const [openDetails, setOpenDetails] = useState(false)
    const [selectedId, setSelectedId] = useState(null);
    const [selectedFlavor, setSelectedFlavor] = useState("")
    const [deselectFlavor, setDeselectFlavor] = useState("")
    const selectedFlavors = selectedFlavor.split(',');
    const [showFlavors, setShowFlavors] = useState(false);
    const [openRuletka , setOpenRuletka] = useState(false);
    const [isRuletkaRunning, setIsRuletkaRunning] = useState(false);


    useEffect(() => {


        const events = ['click', 'mousemove', 'keypress', 'touch', 'touchmove'];
        const resetTimer = () => {
            clearTimeout(window.inactivityTimer);
            window.inactivityTimer = setTimeout(() => {
                // Wywołanie funkcji przekazanej z nadrzędnego komponentu
                onInactive();
            }, 120000); // 60000 ms = 1 minuta
        };

        events.forEach(event => {
            window.addEventListener(event, resetTimer);
        });

        // Ustawienie początkowego timeoutu
        resetTimer();

        // Funkcja czyszcząca
        return () => {
            clearTimeout(window.inactivityTimer);
            events.forEach(event => {
                window.removeEventListener(event, resetTimer);
            });
        };

    }, [onInactive]); // Reaktywuj useEffect gdy zmienia się funkcja onInactive

    const [showTagi, setShowTagi] = useState(false);

    const handleOtworzTagi = () => {
        setShowTagi(true);
    }
    const handleZamknijTagi = () => {
        setShowTagi(false);
    }
    useEffect(() => {
        itemContainerRef.current.scrollTo(0, 0);
    }, [category]);


    const [data, setData] = useState([]);
    const server_url = "https://vc-catalog-api.omnihub.tech/"//"http://127.0.0.1:8000/"

    useEffect(() => {
        const fetchData = async () => {
            await fetch(`${server_url}product_list/home/${sklep}` + (sklep !== "" ? "/" : ""), {
                method: 'GET',
                headers: {
                    'Authorization': 'Api-Key jNewbFc5.xbDd0Iu22AZbUz2Lp9OWjlyJFOOatSd0'
                }
            })
                .then((response) => response.json())
                .then((json) => {
                    setData(json);
                });
        }
        fetchData();
    }, []);


    useEffect(() => {
        if (itemContainerRef.current) {
            itemContainerRef.current.scrollTo(0, 0);
        }
    }, [category], [mark]);

    const anyBestsellerItems = (data.filter(item => item.bestseller)).length > 0;

    const handleDetails = (id) => {
        setOpenDetails(!openDetails);
        setSelectedId(id);
        // console.log("id itemu: " , id )
    }
    
    const closePopup = () => {
        setOpenDetails(false);
        setSelectedId(null);
    }

    const handleDeselectFlavor = (flavorToRemove) => {
        setSelectedFlavor(selectedFlavor.split(',').filter(flavor => flavor !== flavorToRemove).join(','));
    }
    
    const handleOpenRuletka = () => {
        if (!isRuletkaRunning) {
            setOpenRuletka(!openRuletka);
            // console.log(openRuletka)
        }
    }

    let bothFlavorsItems = data.filter(item => (category ? item.category === category : true) && (mark ? item.mark === mark : true) && (selectedFlavor ? selectedFlavors.every(flavor => item.flavor.includes(flavor.toLowerCase())) : true));

    let singleFlavorItems = data.filter(item => (category ? item.category === category : true) && (mark ? item.mark === mark : true) && (selectedFlavor ? selectedFlavors.some(flavor => item.flavor.includes(flavor.toLowerCase())) : true) && !bothFlavorsItems.includes(item));

    let finalItems = [...bothFlavorsItems, ...singleFlavorItems];

    // console.log(mark , singleFlavorItems, bothFlavorsItems)

    //if (selectedFlavor.split(",").length > 0)
    //    finalItems = finalItems.sort((a, b) => a.flavor.length - b.flavor.length)

    finalItems.sort((a, b) => {
        let aHasBothFlavors = selectedFlavors.every(flavor => a.flavor.includes(flavor.toLowerCase()));
        let bHasBothFlavors = selectedFlavors.every(flavor => b.flavor.includes(flavor.toLowerCase()));

        let aFlavorCount = selectedFlavors.reduce((count, flavor) => a.flavor.includes(flavor.toLowerCase()) ? count + 1 : count, 0);
        let bFlavorCount = selectedFlavors.reduce((count, flavor) => b.flavor.includes(flavor.toLowerCase()) ? count + 1 : count, 0);

        if (aHasBothFlavors && !bHasBothFlavors) {
            return -1;
        } else if (!aHasBothFlavors && bHasBothFlavors) {
            return 1;
        } else if (aFlavorCount > bFlavorCount) {
            return -1;
        } else if (aFlavorCount < bFlavorCount) {
            return 1;
        } else {
            return 0;
        }
    });


    return (
        <>
            <div className='main'>
                <div>
                    <Sidebar setCategory={setCategory} category={category} mark={mark} setMark={setMark} anyCategoryOpen={anyCategoryOpen} setAnyCategoryOpen={setAnyCategoryOpen} openItem={openItem} setOpenItem={setOpenItem} itemContainerRef={itemContainerRef} sklep={sklep}></Sidebar>
                </div>
                <div className='container-searchbar'>
                    <div className='fiexd-button' style={{}}>
                        <div className='container-search' onClick={handleOtworzTagi}>
                            {/*<Glowny sklep={sklep} handleDetails={handleDetails} selectedId={selectedId}></Glowny>*/}
                            <button  className='button tagi'>Wybierz smaki</button>
                        </div>
                        <div className={`test ${showTagi ? 'tagi-fixed' : ''}`}>
                            {showTagi && <Tagi onClose={handleZamknijTagi} sklep={sklep} selectedFlavor={selectedFlavor} setSelectedFlavor={setSelectedFlavor} showFlavors={showFlavors} setShowFlavors={setShowFlavors} />}
                        </div>
                        <div className='selected-flavor-container'>
                        {showFlavors && selectedFlavor && (
                            <div className='selected-flavor-container'>
                                {selectedFlavor.split(',').map((flavor, index) => (
                                    <div key={index} className='selected-flavor' onClick={() => handleDeselectFlavor(flavor)}>
                                        {flavor}  
                                        <i className="bi-x-lg" style={{ fontSize: '1.20rem' }}></i>
                                    </div>
                                ))}
                            </div>
                        )}
                        </div>
                    </div>
                    <div className='item-container' ref={itemContainerRef}>

                {finalItems.map((item) => (
                    <>
                    <div className='content' onClick={() => handleDetails(item.id)}>
                        <div className='bestseller'>
                            <h3> {item.bestseller && <i className="bi bi-star-fill" style={{color: '#FFCC00'}}></i> } {item.bestseller ? "Bestseller" : ''}</h3>
                        </div>
                        <div className='image-container'>

                            <img src={item.image} loading={"lazy"} />
                            {/*<ImageWebp*/}
                            {/*    srcWebp={item.image}*/}
                            {/*    src={item.image}*/}
                            {/*/>*/}

                        </div>
                        <div className='text-container'>
                            <div className='item-title'><h4>{item.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}</h4></div>
                            <div className='item-flavor'>
                            {item.flavor.map((flavor, index) => (
                                <p key={index} className={selectedFlavors.map(f => f.toLowerCase()).includes(flavor.toLowerCase()) ? "selected-flavor" : ''}>
                                    {flavor.charAt(0).toUpperCase() + flavor.slice(1).toLowerCase()}
                                </p>
                            ))}
                            </div>
                        </div>
                    </div>
                    {openDetails && selectedId === item.id && 
                        <>
                            {/* <div className='' style={{width: "260px", height: "100%", backgroundColor: "pink", position: "absolute", left: "0", top: "0", zIndex: "1000"}}></div> */}
                            <div className='overlay' onClick={closePopup}></div>
                            <div className='popup'> 
                                <div className='popup-content'>
                                    <div className='popup-img-container'><img src={item.image}></img></div>
                                    <div className='popup-text-container'>
                                        {item.bestseller && 
                                            <div className='bestseller'>
                                                <h3><i className="bi bi-star-fill" style={{color: '#FFCC00'}}></i> Bestseller</h3>
                                            </div>
                                        }
                                        <div className=''>
                                            <h2> {item.mark}</h2>
                                            <ul>
                                                <li>{item.name.charAt(0).toUpperCase() + item.name.slice(1)}</li>
                                            </ul>
                                        </div>
                                        <div>
                                            <h4>Smaki:</h4>
                                                <ul>
                                                    {item.flavor.map((flavor, index) => (
                                                        <li key={index}>{flavor.charAt(0).toUpperCase() + flavor.slice(1).toLowerCase()}</li>
                                                    ))}
                                                </ul>
                                        </div> 
                                        <div className='popup-line'>
                                            <h4>Kategoria: </h4> <p>{item.category}</p>
                                        </div>
                                        <div className='popup-line-margin'>
                                            <h4>Moc: </h4> <p>{item.quantity}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </>
            ))}
                    </div>
                    {anyBestsellerItems && (
                        <>
                            <div className='ruletka-container'>
                                <div className='ruletka' onClick={handleOpenRuletka}><FaDice/></div>
                            </div>
                            {openRuletka && (
                                <Ruletka handleOpenRuletka={handleOpenRuletka} items={data} setIsRuletkaRunning={setIsRuletkaRunning}></Ruletka>
                            )}
                        </>
                    )}

                </div>
            </div>
        </>
    );
}

export default Home;
