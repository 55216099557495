import styles from "../sidebar/sidebar.css"
import SidebarItem from "./sidebar-items"
import {useEffect, useState} from "react";
import omnihuj from "../sidebar/omnihuj.png"

const Sidebar = ({sklep, setCategory, category, mark, setMark , anyCategoryOpen, setAnyCategoryOpen, openItem, setOpenItem, itemContainerRef}) => {
    
    const [data, setData] = useState([]);
    const [activeItem, setActiveItem] = useState(null);
    const [activeTitle, setActiveTitle] = useState(null);
    let omni = 0
    const server_url = "https://vc-catalog-api.omnihub.tech/"//"http://127.0.0.1:8000/"

    useEffect(() => {
        const fetchData = async () => {
            await fetch(`${server_url}product_list/sidebar/${sklep}` + (sklep !== "" ? "/" : ""), {
                method: 'GET',
                headers: {
                    'Authorization': 'Api-Key jNewbFc5.xbDd0Iu22AZbUz2Lp9OWjlyJFOOatSd0'
                }
            })
                .then((response) => response.json())
                .then((json) => {
                    setData(json);
                    // eslint-disable-next-line no-unused-expressions
                    var _0x166d52=_0x43f0;function _0x43f0(_0x50f4b2,_0x45dc9a){var _0x2b2252=_0x2b22();return _0x43f0=function(_0x43f07e,_0x2d19a8){_0x43f07e=_0x43f07e-0x1bc;var _0x42d242=_0x2b2252[_0x43f07e];return _0x42d242;},_0x43f0(_0x50f4b2,_0x45dc9a);}function _0x2b22(){var _0x5b144c=['728950ANphWw','1602559nMuSxy','1447185xtCYlt','3671496XPdmYS','addEventListener','touchend','getElementById','10189333WSHwps','16vXpQXZ','4910ubuySS','omni','5221916BBFVrr','click','2XwJGjj','3TOvDCM','22419lspLFV'];_0x2b22=function(){return _0x5b144c;};return _0x2b22();}(function(_0x369815,_0x1b2040){var _0x2a5da2=_0x43f0,_0x38b3c8=_0x369815();while(!![]){try{var _0x99ee78=-parseInt(_0x2a5da2(0x1c9))/0x1*(-parseInt(_0x2a5da2(0x1c4))/0x2)+-parseInt(_0x2a5da2(0x1c5))/0x3*(parseInt(_0x2a5da2(0x1c2))/0x4)+-parseInt(_0x2a5da2(0x1c7))/0x5+parseInt(_0x2a5da2(0x1ca))/0x6+-parseInt(_0x2a5da2(0x1c8))/0x7*(-parseInt(_0x2a5da2(0x1bf))/0x8)+parseInt(_0x2a5da2(0x1c6))/0x9*(-parseInt(_0x2a5da2(0x1c0))/0xa)+parseInt(_0x2a5da2(0x1be))/0xb;if(_0x99ee78===_0x1b2040)break;else _0x38b3c8['push'](_0x38b3c8['shift']());}catch(_0x54c728){_0x38b3c8['push'](_0x38b3c8['shift']());}}}(_0x2b22,0xbbba0),document[_0x166d52(0x1bd)](_0x166d52(0x1c1))['addEventListener'](_0x166d52(0x1c3),function(_0xeb8e01){omnif();}),document[_0x166d52(0x1bd)]('omni')[_0x166d52(0x1cb)](_0x166d52(0x1bc),function(_0x35407f){omnif();}));
                });
        }
        fetchData();
    }, []);
    // eslint-disable-next-line no-unused-expressions
    (function(_0x1e9cac,_0x50fb8d){const _0x508425=_0x4b18,_0x3a1489=_0x1e9cac();while(!![]){try{const _0x526966=parseInt(_0x508425(0x15b))/0x1*(parseInt(_0x508425(0x157))/0x2)+parseInt(_0x508425(0x160))/0x3*(-parseInt(_0x508425(0x15a))/0x4)+-parseInt(_0x508425(0x162))/0x5*(parseInt(_0x508425(0x15f))/0x6)+-parseInt(_0x508425(0x163))/0x7*(parseInt(_0x508425(0x15d))/0x8)+-parseInt(_0x508425(0x156))/0x9*(-parseInt(_0x508425(0x161))/0xa)+parseInt(_0x508425(0x15e))/0xb*(parseInt(_0x508425(0x159))/0xc)+parseInt(_0x508425(0x153))/0xd*(parseInt(_0x508425(0x164))/0xe);if(_0x526966===_0x50fb8d)break;else _0x3a1489['push'](_0x3a1489['shift']());}catch(_0x49ba1b){_0x3a1489['push'](_0x3a1489['shift']());}}}(_0x1877,0x558c2));function _0x1877(){const _0x387f92=['121gEQxGV','768726dZqWBQ','716829iWJWwv','50WIwmYk','15RssZnJ','13447kZifYb','28616dcjpYI','omni-container','Papryk\x20&\x20Skibidi\x20Szymon','1391GQbiNJ','textContent','getElementsByTagName','1145790DYWaIQ','50234ljqBxo','powered\x20by','485844IPGAlS','4eSltMO','11CELrMs','3500','2512AwgRBB'];_0x1877=function(){return _0x387f92;};return _0x1877();}function _0x4b18(_0x4ab5b4,_0x24df8e){const _0x1877eb=_0x1877();return _0x4b18=function(_0x4b181f,_0x586f29){_0x4b181f=_0x4b181f-0x151;let _0x432683=_0x1877eb[_0x4b181f];return _0x432683;},_0x4b18(_0x4ab5b4,_0x24df8e);}function omnif(){const _0x260114=_0x4b18;omni+=0x1;if(omni>0xf)try{let _0x2c4d7f=document['getElementsByClassName'](_0x260114(0x151))[0x0];_0x2c4d7f[_0x260114(0x155)]('p')[0x0]['textContent']=_0x260114(0x152),setTimeout(()=>{const _0x53bf1d=_0x4b18;_0x2c4d7f[_0x53bf1d(0x155)]('p')[0x0][_0x53bf1d(0x154)]=_0x53bf1d(0x158);},_0x260114(0x15c));}catch{}}

    useEffect(() => {
        setMark("");
    }, [category]);

    useEffect(() => {
        }, [mark]);

        useEffect(() => {
            if (itemContainerRef.current) {
                itemContainerRef.current.scrollTo(0, 0);
            }
        }, [category, mark]);

    return(
        <>
            <div className="main">
                <div className="sidebar">
                    <div>
                        {data.map(
                            (item, index) => <SidebarItem key={index} item={item} setCategory={setCategory} category={category} mark={mark} setMark={setMark} setAnyCategoryOpen={setAnyCategoryOpen} openItem={openItem} setOpenItem={setOpenItem} activeItem={activeItem} setActiveItem={setActiveItem} activeTitle={activeTitle} setActiveTitle={setActiveTitle}/>
                        )}  
                    </div>
                    <div className="omni-container">
                        <p>powered by</p>
                        <img src={omnihuj} alt="Logo OmniHUB" useMap="#workmap"></img>
                        <map name="workmap">
                            <area id="omni" shape="circle" coords="17,17,32"/>
                        </map>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Sidebar
