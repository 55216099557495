import '../style/wstep.css'
import zdjecie from '../components/zdjecie.png'
import logo from '../components/logo.png'
import lq from '../components/lq.png'
import {useState} from 'react'
import Modal from 'react-modal';



const Wstep = ({sklep, link, wersja}) => {
    const [openQR, setOpenQR] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleOtworzQR = (image) => {
        setSelectedImage(image);
        setOpenQR(true);
    }

    const closeModal = () => {
        setOpenQR(false);
    }
    return (
        <>
            <div className='duzy'>
                <div className='lewy'>
                    <div className='zdjecie'>
                        <img src={zdjecie} alt="Background"/>
                    </div>
                </div>
                <div className='prawy'>
                    <div className='sredniprawy'>
                        <div className='tytul'>
                            <h1>Witamy w wirtualnym Katalogu Vape&Chill.</h1>
                        </div>
                        <div className='logo'>
                            <img src={logo} alt="Logo"/>
                        </div>
                    </div>
                    <div className='srednidol'>
                        <div className='opis'>
                            <p>Wirtualny katalog produktów <b>Vape&Chill</b> ułatwi Ci wybór swojego nowego płynu. Dzięki filtrowaniu pozycji według kategorii, marki czy smaku jesteś w stanie wybrać płyn dopasowany do swoich oczekiwań.</p>
                        </div>
                        <div className='odstep'>
                        <a href={(sklep==="" ? "" : "/") + sklep + '/home'}><button className='przycisk'>Zaczynamy</button></a>
                        </div>
                        
                        <div className='lqcontainer'>
                            <img src={lq} alt="Product"/>
                        </div>
                        <div className='kody'>
                            {link !== "" && (
                                <div>
                                    <h2>Opinie</h2>
                                    <img src={link} onClick={() => handleOtworzQR(link)}></img>
                                </div>
                            )}
                        </div>
                    </div>
                    <Modal
                        isOpen={openQR}
                        onRequestClose={closeModal}
                        contentLabel="Wybrany obraz"
                        style={{
                            content: {
                                width: '50%', 
                                height: 'auto', 
                                margin: 'auto', 
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }
                        }}
                    >
                        <img src={selectedImage} alt="Wybrany obraz"/>
                    </Modal>
                    </div>
                </div>
            <div className="wersja">Wersja: {wersja}</div>
        </>
    )
}
export default Wstep
