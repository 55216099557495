import './App.css';
import Wstep from './components/wstep';
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import Home from './components/home/home';
import {useState} from 'react'
import { useEffect } from 'react';


<link href='https://fonts.googleapis.com/css?family=Montserrat' rel='stylesheet'>
  

</link>

const App = () => {

  const [aktualnysklep,Setaktualnysklep] = useState("") //mam nadzieje że sie nie rozwali ;)
  const [Version, setVersion] = useState("")

  const useInactivityRedirect = (navigate) => {
  
    useEffect(() => {
      const events = ['click', 'mousemove', 'keypress', 'touch', 'touchmove'];
      const resetTimer = () => {
          clearTimeout(window.inactivityTimer);
          window.inactivityTimer = setTimeout(() => {
              navigate("/" + aktualnysklep);
          }, 120000); // 60000 ms = 1 minuta
      };
  
      events.forEach(event => {
          window.addEventListener(event, resetTimer);
      });
  
      resetTimer();
  
      return () => {
          clearTimeout(window.inactivityTimer);
          events.forEach(event => {
              window.removeEventListener(event, resetTimer);
          });
      };
    }, [navigate]);
  };

    const [data, setData] = useState([]);
    const server_url = "https://vc-catalog-api.omnihub.tech/"//"http://127.0.0.1:8000/"

    useEffect(() => {
        const fetchData = async () => {
            await fetch(`${server_url}shop_list/`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Api-Key jNewbFc5.xbDd0Iu22AZbUz2Lp9OWjlyJFOOatSd0'
                }
            })
                .then((response) => response.json())
                .then((json) => {
                    setData(json);
                    setVersion(json[0].wersja)
                });
        }
        fetchData();
    }, []);

  
  const IWstep = ({sklep,link}) => {
    const navigate = useNavigate();
    useInactivityRedirect(navigate);
    Setaktualnysklep(sklep)
    return <Wstep sklep={sklep} link={link} wersja={Version}/>
  };
  
  const IHome = ({sklep}) => {
    const navigate = useNavigate();
    useInactivityRedirect(navigate);
    Setaktualnysklep(sklep)
    return <Home sklep={sklep}/>
  };
  
  const IAbout = ({sklep}) => {
    const navigate = useNavigate();
    useInactivityRedirect(navigate);
    Setaktualnysklep(sklep)
    return <Home sklep={sklep}/>
  };
  

  return (
    <>
      <Router>
        <Routes>
            {data.map((item) => (
                <>
                  <Route path={"/" + item.nazwa} element={<IWstep sklep={item.nazwa} link={item.link}/>} />
                  <Route path={"/"  + item.nazwa + "/home"} element={<IHome sklep={item.nazwa}/>} />
                  <Route path={"/"  + item.nazwa + "/about"} element={<IAbout sklep={item.nazwa}/>} />
                </>
            ))}

        </Routes>
      </Router>
    </>
  );
};


export default App;
